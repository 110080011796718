export const validateInputs = ({ textbox2, selectedYear, difficulty, selectedSubjects }, setErrors) => {
    const newErrors = {
      countError: textbox2.trim() === '' ? 'Question Count is required.' : '',
      yearError: !selectedYear ? 'Year group selection is required.' : '',
      difficultyError: !difficulty ? 'Difficulty selection is required.' : '',
      subjectError: !selectedSubjects || selectedSubjects.length === 0 ? 'At least one lesson must be selected.' : '',
    };
  
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };
  
  export const generatePromptText = (textbox2, difficulty, selectedYear, selectedSubjects) => {
    return `Please provide exactly ${textbox2} ${difficulty}-level questions on the following mathematics topics for ${selectedYear} students in the UK curriculum. The topics are: ${selectedSubjects}. 
      Please list only the questions without any additional text or explanations.
      Each question should include a corresponding answer listed in a separate section titled 'Answers', directly following the list of questions.
      Ensure that each question uses inline KaTeX with the dollar signs for rendering LaTeX in web environments.
      Purely mathematical and should be formatted using MathJax.
      Any questions involving multiplication should explicitly use the multiplication sign and not the dot.`;
  };
  
  export const initializeJoyride = (setRun) => {
    const hasVisitedBefore = localStorage.getItem('hasVisited');
    if (!hasVisitedBefore) {
      setRun(true);
      localStorage.setItem('hasVisited', 'true');
    }
  };
  
import React from 'react';
import './App.css';
import Layout from './components/Layout/Layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from'../src/components/PrivateRoute'
import Account from '../src/components/Account/Account'
import Login from './components/Login/loginPage';


const mathJaxConfig = {
  loader: { load: ['input/asciimath', 'output/chtml'] }
};

function App() {
  return (
    <div>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Layout />} />
            <Route path="/account" element={<PrivateRoute element={Account} />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react";
import katex from "katex";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "katex/dist/katex.min.css";
import { handlePrintPDF } from "./createPDF";
import "./Texteditor.css"

import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
  math,
} from "suneditor/src/plugins";




function Texteditor({ questions }) {
    const editorRef = useRef(null);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [errorMessage, setErrorMessage] = useState('');
  


    const validateLaTeX = (latexString) => {
        try {
            katex.renderToString(latexString, { throwOnError: true });
            return { valid: true, errorMessage: '' }; // Valid LaTeX
        } catch (error) {
            if (error instanceof katex.ParseError) {
              console.log(error)
                return { valid: false, errorMessage: `Please Try Again: ${error.message}` };
            }
            return { valid: false, errorMessage: 'An unexpected error occurred.' };
        }
    };




    const preprocessMathContent = (content) => {
      return content
        .replace(/£/g, "pounds") // Replace £ with "pounds"
        .replace(/&/g, "") // Remove all instances of "&"
        .replace(/align\*/g, "aligned") // Fix align* usage
        .replace(/\$(.*?)\$/g, (_, latex) => {
          return `<span class="katex" data-exp="${latex}">${latex}</span>`;
        });
    };

  
      useEffect(() => {
        if (questions.length > 0) {
            setLoading(false); // Set loading to false when questions are ready
            const editor = editorRef.current;
            if (editor) {
                const rawContent = questions.join("<br><br><br><br><br><br>");
                const processedContent = preprocessMathContent(rawContent);
                
                // Validate the processed content
                const validationResult = validateLaTeX(processedContent);
                if (validationResult.valid) {
                    editor.setContents(processedContent);
                    setErrorMessage(''); // Clear any previous error messages
                } else {
                    setErrorMessage(validationResult.errorMessage); // Set error message
                }
            }
        }
    }, [questions]);

      

  return (

    <div className="App">
      
    <SunEditor
      showToolbar={true}
      setDefaultStyle="height: 500px"
      getSunEditorInstance={(sunEditor) => {
        editorRef.current = sunEditor;
        console.log("Editor is ready"); // Debug log
        setIsEditorReady(true);
    }}
      setOptions={{
        katex: katex,
        plugins: [
          font,
          fontSize,
          align,
          fontColor,
          formatBlock,
          hiliteColor,
          horizontalRule,
          lineHeight,
          list,
          paragraphStyle,
          template,
          textStyle,
          image,
          link,
          math,
          table,
        ],
        buttonList: [
          [
            "bold",
            "underline",
            "italic",
            "strike",
            "list",
            "align",
            "fontSize",
            "formatBlock",
            "table",
            "image",
            "codeView",
            "math",
            "link",
            "fullScreen",
          ],
        ],
      }}
    />
    
    <p>{loading ? "Generating PDF..." :  " "}</p>
    {errorMessage && (
        <p style={{ color: 'red' }}>
            {errorMessage}
        </p>
    )}
    { !loading && !errorMessage && (
        <button 
        className="txt-create-pdfbutton"
         onClick={() => handlePrintPDF(editorRef, setLoading)}
          disabled={loading }
        
        
        >
          
          
          Create PDF</button>
    )}
    {loading && <div className="loading-icon"></div>} {/* Loading icon */}
    </div>
  )
}

export default Texteditor

import { useEffect, useState } from "react";
import Joyride from 'react-joyride';
import { Template2Steps } from '../../data/joyrideSteps.js';
import { useUsageLimit } from "../../customhooks/useUsageLimit.js";
import { useAuth } from "../Login/AuthContext.js";
import './Columnquestions.css';


const Columnquestions = ({ generateContentAndPdf }) => {
  const [prompt, setPrompt] = useState(null);
  const [selectedOperation, setOperation] = useState(null);
  const [firstNumberDigits, setFirstNumberDigits] = useState(4); // Default to 4 digits for the first number
  const [secondNumberDigits, setSecondNumberDigits] = useState(3);
  const [questionCount, setquestionCount] = useState(20);
  const [run, setRun] = useState(false);
  
  const { isAuthenticated } = useAuth(); 
  const { remainingUses, isDisabled, incrementUsage } = useUsageLimit("globalUsage", 5, undefined, isAuthenticated);

  const [errors, setErrors] = useState({
    operationError: '',
    firstNumError: '',
    secondNumError: '',
  });


  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisited1');
    if (!hasVisitedBefore) {
      setRun(true);
      localStorage.setItem('hasVisited1', 'true');
    }
  }, []);

 
  const statusText = `Generate a list of ${questionCount} ${selectedOperation} questions where the first number is ${firstNumberDigits} digits and the second number is ${secondNumberDigits} digits. 
  Do not use commas to show placeholders. Please also provide the answers separately after the questions under a title that says "Answers".`;

  useEffect(() => {
    setPrompt(statusText);
  }, [selectedOperation, firstNumberDigits, secondNumberDigits, questionCount]);





  const submitHandler = (e) => {
    e.preventDefault();

    if (!isAuthenticated && isDisabled) {
      alert('You have reached the maximum number of uses. Please sign up for the beta period of unlimited access! 👉🏻');
      return;
    }

    setErrors({
      operationError: '',
      firstNumError: '',
      secondNumError: '',
    });

    let hasError = false;

    
    if (!selectedOperation) {
      setErrors(prevErrors => ({ ...prevErrors, operationError: 'Please select an operation.👆🏻' }));
      hasError = true;
    }
    if (firstNumberDigits < 1 || firstNumberDigits > 6) {
      setErrors(prevErrors => ({ ...prevErrors, firstNumError: 'Please enter a valid number of digits for the 1st number.' }));
      hasError = true;
    }
    if (secondNumberDigits < 1 || secondNumberDigits > 6) {
      setErrors(prevErrors => ({ ...prevErrors, secondNumError: 'Please enter a valid number of digits for the 2nd number.' }));
      hasError = true;
    }

   
    if (!hasError && !isDisabled) {
      if (!isAuthenticated) incrementUsage();
      generateContentAndPdf(prompt); 
    }
  };

 
  const changeone = (e) => {
    const value = e.target.value;
    if (value === "" || (value >= 1 && value <= 6)) {
      setFirstNumberDigits(value);
      if (value.trim() !== '') {
        setErrors((prevErrors) => ({ ...prevErrors, firstNumError: '' }));
      }
    }
  };

  
  const changetwo = (e) => {
    const value = e.target.value;
    if (value === "" || (value >= 1 && value <= 6)) {
      setSecondNumberDigits(value);
      if (value.trim() !== '') {
        setErrors((prevErrors) => ({ ...prevErrors, secondNumError: '' }));
      }
    }
  };

  return (
    <div className="column-questions">
      <Joyride
        steps={Template2Steps}
        run={run}
        continuous
        scrollToFirstStep
        showSkipButton
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />
      
      {/* Operation Buttons */}
      <div className="operation-buttons-div">
        <button 
          className={`operation-button ${selectedOperation === 'addition' ? 'selected' : ''}`}
          onClick={() => { setOperation('addition'); }}>
          Addition
        </button>
        <button 
          className={`operation-button ${selectedOperation === 'subtraction' ? 'selected' : ''}`}
          onClick={() => { setOperation('subtraction'); }}>
          Subtraction
        </button>
        <button 
          className={`operation-button ${selectedOperation === 'multiplication' ? 'selected' : ''}`}
          onClick={() => { setOperation('multiplication'); }}>
          Multiplication
        </button>
      </div>
      {errors.operationError && <p className="error-msg">{errors.operationError}</p>}

      <div className="inputs-container">
        {/* First Number Digits Input */}
        <div className="input-group">
          <label 
          className="first-number"
          htmlFor="first-number">1st Number Digit Count:</label>
          <input 
            className="input-column-temp"
            id="first-number"
            type="number"
            value={firstNumberDigits}
            onChange={changeone} 
            min="1"
            max="6"
          />
          {errors.firstNumError && <p className="error-msg">{errors.firstNumError}</p>}
        </div>

        {/* Second Number Digits Input */}
        <div className="input-group">
          <label 
          className="second-number"
          htmlFor="second-number">2nd Number Digit Count:</label>
          <input 
            className="input-column-temp"
            id="second-number"
            type="number"
            value={secondNumberDigits}
            onChange={changetwo} 
            min="0"
            max="6"
          />
          {errors.secondNumError && <p className="error-msg">{errors.secondNumError}</p>}
        </div>
      </div>

      {/* Generate Button */}
      <div className="generate-button-div">
        <button 
          className="generate-button"
          onClick={submitHandler}
          disabled={firstNumberDigits < 1 || secondNumberDigits < 1}
          >
          {isAuthenticated ? 'Generate ✨' : `Generate (${remainingUses} remaining)`}
         </button>
        
      </div>
    </div>
  );
};

export default Columnquestions;

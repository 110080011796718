import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient/supabaseClient';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();

      if (session) {
      setAuthenticated(true);  // true if session exists
    }
      setLoading(false);
    };
    checkUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Show loading while checking authentication
  }

  return authenticated ? (
    <Element {...rest} />
  ) : (
    navigate('/login', { replace: true })  // Redirect to login if not authenticated
  );
};

export default PrivateRoute;

import { useContext, useEffect, useState } from "react";
import Joyride from 'react-joyride';
import { Template1Steps } from "../../data/joyrideSteps.js";
import { useAuth } from "../Login/AuthContext.js";
import { useUsageLimit } from "../../customhooks/useUsageLimit.js";
import { validateInputs, generatePromptText, initializeJoyride } from "./aiPromptHelper/aiPromptHelper.js";
import { Context } from "../Layout/Layout";

import Yeargroupbuttons from "../YearGroup/Yeargroup";
import Difficlutybuttons from '../Difficulty/difficulty';
import SubjectsLibrary from "../Subjects/Subject";


import './aiprompt.css';


export default function AiGenerate({ generateContentAndPdf }) {
  const { selectedSubjects, setSelectedSubjects, textbox1, setTextbox1 } = useContext(Context);
  const [prompt, setPrompt] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [open, setOpen] = useState(false);
  const [textbox2, setTextbox2] = useState('');
  const [difficulty, setDifficulty] = useState(null);
  const [run, setRun] = useState(false);
  const [errors, setErrors] = useState({
    nameError: '',
    countError: '',
    yearError: '',
    difficultyError: '',
    subjectError: '',
  });

  const { isAuthenticated } = useAuth(); 

 
  const { remainingUses, isDisabled, incrementUsage } = useUsageLimit("globalUsage", 5, undefined, isAuthenticated);

  useEffect(() => initializeJoyride(setRun), []);

  useEffect(() => {
    setPrompt(generatePromptText(textbox2, difficulty, selectedYear, selectedSubjects));
  }, [textbox2, difficulty, selectedYear, selectedSubjects]);

 
  const submitHandler = (e) => {
    e.preventDefault();

    if (!isAuthenticated && isDisabled) {
      alert('You have reached the maximum number of uses. Please sign up for the beta period of unlimited access! 👉🏻');
      return;
    }

    if (validateInputs({ textbox1, textbox2, selectedYear, difficulty, selectedSubjects }, setErrors)) {
      if (!isAuthenticated) incrementUsage();
      generateContentAndPdf(prompt);
    }
  };

  
  const handleInputChange = (setter, field, value, max) => {
    const numberValue = parseInt(value, 10);
  
    // If the value exceeds the maximum, don't update the state
    if (numberValue > max) return;
  
    setter(value); // Update state with the valid value
    
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '',
      }));
    }
  };

 
  const handleSelection = (setter, field, value) => {
    setter(value);
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    }
  };

  return (
    <div>
      <section>
        <Joyride
          steps={Template1Steps}
          run={run}
          continuous
          scrollToFirstStep
          showSkipButton
          styles={{ options: { zIndex: 10000 } }}
        />
        <div className="basic_details">
          {/* <input
            className="basic_details_name"
            placeholder="Worksheet Name"
            value={textbox1}
            onChange={(e) => handleInputChange(setTextbox1, 'nameError', e.target.value)}
            type="text"
          />
          {errors.nameError && <p className="error">{errors.nameError}</p>} */}
          
          <input
  className="basic_details_count"
  placeholder="Question Count"
  value={textbox2}
  onChange={(e) => handleInputChange(setTextbox2, 'countError', e.target.value, 30)}
  type="number"
/>
          {errors.countError && <p className="error">{errors.countError}</p>}
        </div>
      </section>

      <section>
        <div className="yeargroup-buttons-div">
          <Yeargroupbuttons selectedYeargroup={selectedYear} onClick={(year) => handleSelection(setSelectedYear, 'yearError', year)} />
          {errors.yearError && <p className="error">{errors.yearError}</p>}
        </div>
        <div className="difficluty-buttons-div">
          <Difficlutybuttons selectedDifficulty={difficulty} onClick={(diff) => handleSelection(setDifficulty, 'difficultyError', diff)} />
        </div>
        {errors.difficultyError && <p className="error">{errors.difficultyError}</p>}
        <div className="subjects-library-div">
          <button className="add-lessons-button" onClick={() => setOpen(true)}>
            Add Lessons
          </button>
          <SubjectsLibrary open={open} onSelect={(subjects) => handleSelection(setSelectedSubjects, 'subjectError', subjects)} onClose={() => setOpen(false)} />
        </div>
        {errors.subjectError && <p className="error">{errors.subjectError}</p>}
      </section>

      <div className="generate-button-div">
        <button 
          className="generate-button" 
          onClick={submitHandler}
        >
         {isAuthenticated ? 'Generate ✨' : `Generate (${remainingUses} remaining)`}
        </button>
      </div>
    </div>
  );
}

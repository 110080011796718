import { useState, useEffect } from "react";

export const useUsageLimit = (key, maxUses, resetTimeInMs = 24 * 60 * 60 * 1000, isAuthenticated = false) => {
  const [remainingUses, setRemainingUses] = useState(maxUses);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      // If authenticated, no limit applies
      setRemainingUses(Infinity);
      setIsDisabled(false);
      return;
    }
   
    const storedData = JSON.parse(localStorage.getItem("globalUsage")) || { count: 0, timestamp: Date.now() };


    if (Date.now() - storedData.timestamp > resetTimeInMs) {
      storedData.count = 0;
      storedData.timestamp = Date.now();
    }

    const remaining = maxUses - storedData.count;
    setRemainingUses(remaining);
    setIsDisabled(storedData.count >= maxUses);


    localStorage.setItem("globalUsage", JSON.stringify(storedData));
  }, [maxUses, resetTimeInMs]);

  const incrementUsage = () => {
    if (isAuthenticated) return; 
    
    const storedData = JSON.parse(localStorage.getItem("globalUsage")) || { count: 0, timestamp: Date.now() };

    if (storedData.count < maxUses) {
      storedData.count++;
      storedData.timestamp = Date.now();
      localStorage.setItem("globalUsage", JSON.stringify(storedData));

      const remaining = maxUses - storedData.count;
      setRemainingUses(remaining);
      setIsDisabled(storedData.count >= maxUses);
    }
  };

  return { remainingUses, isDisabled, incrementUsage };
};

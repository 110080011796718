import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const handlePrintPDF = async (editorRef, setLoading) => {
    if (editorRef.current) {
        setLoading(true); // Set loading state to true
        const contentHTML = editorRef.current.getContents();

        // Split content into chunks of 10 questions
        const questions = contentHTML.split("<br><br><br><br><br><br>");
        const chunks = [];
        for (let i = 0; i < questions.length; i += 10) {
            chunks.push(questions.slice(i, i + 10).join("<br><br><br><br><br><br>"));
        }

        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 350;

        for (const chunk of chunks) {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = chunk;
            document.body.appendChild(tempDiv);

            const canvas = await html2canvas(tempDiv, {
                scale: 2,
                useCORS: true,
            });
            const imgData = canvas.toDataURL("image/png");
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
            document.body.removeChild(tempDiv);

            // Add a new page if there are more chunks
            if (chunk !== chunks[chunks.length - 1]) {
                pdf.addPage();
            }
        }

        const pdfBlobUrl = pdf.output("bloburl");
        window.open(pdfBlobUrl, "_blank");

        setLoading(false); // Reset loading state after completion
    }
};

import { createContext, useContext, useEffect, useState } from "react";
import supabase from "../../supabaseClient/supabaseClient";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);


  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session?.access_token); // Check for access_token in session
      setUser(session?.user || null); // Store the user object or null if no session
    };
    
    fetchSession(); // Initial session check

    // Listen for auth state changes
    const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
        setIsAuthenticated(!!session?.access_token); // Update state based on auth changes
        setUser(session?.user || null);
    });

    // Cleanup listener on component unmount
    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);


  



  return (
    <AuthContext.Provider value={{ isAuthenticated, user}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

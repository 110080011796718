import Navbar from '../Navbar/navbar'
import React, { useEffect, useState } from 'react';
import './Account.css'

import { TextField, Button, MenuItem } from '@mui/material';
import supabase from '../../supabaseClient/supabaseClient'
import { roles, countries } from './dropdowns';

const Account = () => {
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
      email: '',
      name: '',
      role: '',
      school: '',
      country: '',
    });
    const [initialData, setInitialData] = useState({});
    const [isFormModified, setIsFormModified] = useState(false);


    const handleMenuToggle = (state) => {
        setMenuOpen(state);
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
      checkFormModified({ ...formData, [name]: value });
    };
  
    
      const checkFormModified = (currentData) => {
        setIsFormModified(
          JSON.stringify(currentData) !== JSON.stringify(initialData)
        );
      };


      const fetchProfileData = async () => {
        const {
            data: { session },
            error: sessionError,
        } = await supabase.auth.getSession();
    
        if (sessionError) {
            console.error('Error fetching session:', sessionError.message);
            return;
        }
    
        if (session) {
            const { email } = session.user;
    
            try {
                const { data, error } = await supabase
                    .from('profiles')
                    .select('*')
                    .eq('email', email)
                    .single();
    
                if (error && error.code !== 'PGRST116') {
                    // Handle unexpected errors except "no row found"
                    console.error('Error fetching profile:', error.message);
                } else if (data) {
                    // Handle cases where data exists but may contain null fields
                    const sanitizedData = {
                        email: data.email || email,
                        name: data.name || '',
                        role: data.role || '',
                        school: data.school || '',
                        country: data.country || '',
                    };
    
                    setFormData(sanitizedData);
                    setInitialData(sanitizedData);
                } else {
                    // No profile data found, set defaults
                    const defaultData = {
                        email,
                        name: '',
                        role: '',
                        school: '',
                        country: '',
                    };
    
                    setFormData(defaultData);
                    setInitialData(defaultData);
                }
            } catch (error) {
                console.error('Unexpected error:', error);
            }
        }
    };
    
    


    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const { error } = await supabase
              .from('profiles')
              .upsert(formData, { onConflict: ['email'] }); 
          if (error) {
              console.error('Error updating profile:', error.message);
          } else {
              console.log('Profile updated successfully');
              setInitialData(formData); // Sync initialData with submitted form
              setIsEditing(false);
          }
      } catch (error) {
          console.error('Unexpected error:', error);
      }
  };
  
    


      useEffect(() => {
        fetchProfileData();
    }, []);


  return (
    <div>
        
        <Navbar onMenuToggle={handleMenuToggle} />
        <div 
      className={`account-body ${isMenuOpen ? "menu-open" : ""}`}
      style={{
      marginLeft: isMenuOpen ? "250px" : "0", // Adjust based on menu state
      transition: "margin-left 0.3s ease-in-out", // Smooth transition
      }}
      >



              <form
                    onSubmit={handleSubmit}
                    className="form-container"
                >
                    <h3 className="profile-title">Profile</h3>

                    <TextField
                        className="email-input"
                        label="Email"
                        name="email"
                        value={formData.email}
                        margin="normal"
                        disabled
                    />
                    <div className="profile-details">
                        <TextField
                            className="name-input"
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            margin="normal"
                            required
                            disabled={!isEditing}
                        />
                        <TextField
                            className="role-select"
                            select
                            label="Academic Role"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            required
                            disabled={!isEditing}
                        >
                            {roles.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            className="school-input"
                            label="School"
                            name="school"
                            value={formData.school}
                            onChange={handleChange}
                            margin="normal"
                            required
                            disabled={!isEditing}
                        />
                        <TextField
                            className="country-select"
                            select
                            label="Country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            margin="normal"
                            required
                            disabled={!isEditing}
                        >
                            {countries.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    {!isEditing ? (
                        <Button
                            className="edit-button"
                            variant="contained"
                            color="primary"
                            onClick={() => setIsEditing(true)}
                        >
                            Edit
                        </Button>
                    ) : (
                        <Button
                            className="submit-button"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!isFormModified}
                        >
                            Submit
                        </Button>
                    )}
                </form>

</div>
    </div>
  )
}

export default Account
import React, { useEffect } from 'react';

import './QuestionsPreview.css'; 
// Configure MathJax options
const mathJaxConfig = {
  loader: { load: ['input/asciimath', 'output/chtml'] },
};

const QuestionsPreview = ({ questions, refs }) => {
  useEffect(() => {
    // Force MathJax to typeset the content after it is mounted
    if (window.MathJax) {
      window.MathJax.typeset();
    }
  }, [questions]); // Re-run when questions change

  return (

      <div className="questions-preview-container">
        {questions.length > 0 ? (
          questions.map((item, index) => (
            <div key={index} ref={(el) => (refs.current[index] = el)} className="question-item">
              {item.question ? (
                item.question.latex ? (
                  <p>Test</p>
                  // <p><MathJax>{item.question.latex}</MathJax></p>
                ) : (
                  <p>{item.question.text}</p>
                )
              ) : item.answer ? (
                <div className="answer-item">
                  <strong>Answer:</strong>
                  {item.answer.latex ? (
                    <p>Test</p>
                    // <p><MathJax>{item.answer.latex}</MathJax></p>
                  ) : (
                    <p>{item.answer.text}</p>
                  )}
                </div>
              ) : null}
            </div>
          ))
        ) : (
          <div className="placeholder">
            <p>No questions to display yet. Please generate some questions to view them here.</p>
          </div>
        )}
      </div>
  );
};

export default QuestionsPreview;
import React from 'react'
import supabase from '../../supabaseClient/supabaseClient'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './loginPage.css'
import Logo from '../Navbar/sheep.svg';
import WorkSheepImage from '../../assets/login.png'

const Login = () => {


    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const checkUser = async () => {
          const { data: { session } } = await supabase.auth.getSession();
    
          if (session) {
            // User is already signed in, navigate to the app
            navigate('/');
          }
        };
    
        checkUser();
    
        // Listen for auth state changes
        const { data: listener } = supabase.auth.onAuthStateChange((event) => {
          if (event === 'SIGNED_IN') {
            navigate('/');
          }
        });
    
        // Cleanup listener on component unmount
        return () => {
          listener.subscription.unsubscribe();
        };
      }, [navigate]);




  
      const handleMagicLinkSignIn = async () => {
        if (!email) {
          setError("Please enter your email address.");
          return;
        }
    
        setLoading(true); // Start loader
        setError(null);   // Clear any previous errors
        setSuccessMessage(null); // Clear previous success messages
    
        try {
          const { error } = await supabase.auth.signInWithOtp({
            email,
            options: {
              shouldCreateUser: true,
              emailRedirectTo: "https://app.theworksheep.com",
            },
          });
    
          if (error) {
            setError(error.message);
          } else {
            setSuccessMessage("Check your email for the magic link!");
          }
        } catch (err) {
          setError("Something went wrong. Please try again.");
        } finally {
          setLoading(false); // Stop loader
        }
      };
    
      



      return (
        <div className="login-page">
        <div className="login-container">
          {/* Left Side */}
          <div className="login-left">
            <img
              src={WorkSheepImage}
              alt="About Worksheep"
              className="worksheep-image"
            />
          </div>
  
          {/* Right Side */}
          <div className="login-right">
            <div className="login-header">
              <img src={Logo} alt="Worksheep_logo" className="worksheep-logo" />
              <h1 className="worksheep-heading">Worksheep</h1>
            </div>
  
            {/* <h4 className='login-titles' >Log In</h4> */}
  
            <div className="auth-wrapper">
              <Auth
                supabaseClient={supabase}
                view="sign_in"
                appearance={{ theme: ThemeSupa }}
                localization={{
                  variables: {
                    sign_in: {
                      email_label: "",
                      password_label: "",
                      email_input_placeholder: "Email Address",
                      password_input_placeholder: " Password",
                    },
                    sign_up: {
                      email_label: "",
                      password_label: "",
                      email_input_placeholder: "Email address",
                      password_input_placeholder: " New Password",
                    },
                  },
                }}
                providers={[]}
              />
            </div>

    
    <span
    className='line'
    >___________________________________________</span>


            <div className="magic-link-form">
              <h4 className='login-titles2'>We'll send you a link for password-less sign-on!</h4>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="email-input"
              />
 <button onClick={handleMagicLinkSignIn} className="magic-link-button" disabled={loading}>
        {loading ? "Sending..." : "Send Link"}
      </button>
      {loading && <div className="magic-loader"></div>} {/* Loader */}
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
            </div>


          </div>
        </div>
      </div>
);
};

export default Login;

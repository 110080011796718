import React, { useState, useEffect } from 'react';
import './Popup.css'; 


const Popup = () => {
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        const hasSeenPopup = localStorage.getItem('hasSeenPopup');
        if (!hasSeenPopup) {
            setIsOpen(true); // Show the popup if not seen
        }
    }, []);


    const closePopup = async () => {
        setIsOpen(false); // Just close the popup without email validation
        localStorage.setItem('hasSeenPopup', 'true'); // Mark as seen
    };


    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('overlay')) {
            closePopup();
        }
    };

    return (
        isOpen && (
            <div className="overlay" onClick={handleOverlayClick}>
                <div className="popup">
                    <h2>Welcome to Worksheep!</h2>
                    <p>We're thrilled to introduce exciting new features and improvements to help you create custom worksheets in no time!</p>
                    <p><strong>For the best experience, we recommend using a PC.</strong></p>
                    <p>With Worksheep, you can easily create, import, and customize Math worksheets. Choose topics, set difficulty levels, and align content with curriculum standards—all on our intuitive platform.</p>
                    {/* <p>
  By continuing, you agree to our 
  <a 
  className='tos-link'
  href="https://www.theworksheep.com/policies/Terms%20of%20Service%20-%20Worksheep.pdf" target="_blank"> Terms of Service </a> 
  and 
  <a 
  className='pp-link'
  href="https://www.theworksheep.com/policies/Privacy%20Policy%20-%20Worksheep.pdf" target="_blank"> Privacy Policy </a>, 
  and confirm that you are 18 years of age or older.
</p> */}
                    <p>Any questions? Reach out at <a href="mailto:hello@theworksheep.com">hello@theworksheep.com</a>!</p>
    
                    <button onClick={closePopup}>
                        Continue
                    </button>
                </div>
            </div>
        )
    );
};

export default Popup;
